import Countdown from 'react-countdown';
import { Div } from 'styles';
import {
  ClockNumWrapper,
  ClockSeparator,
  ClockWrapper,
  GradientText,
} from '../Home.styled';

const CountdownComponent = () => {
  const dubaiEndDate = '2025-03-23T10:59:59.773Z';
  const date = new Date(dubaiEndDate);
  const timestamp = date.getTime();

  return <Countdown date={timestamp} renderer={renderer} />;
};

interface RendererProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

// ✅ Fix: Correct function type
const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: RendererProps) => {
  if (completed) {
    return <span></span>;
  }
  return (
    <ClockWrapper>
      <ClockNumber number={days} text='Days' />
      <ClockSeparator />
      <ClockNumber number={hours} text='Hours' />
      <ClockSeparator />
      <ClockNumber number={minutes} text='Minutes' />
      <ClockSeparator />
      <ClockNumber number={seconds} text='Seconds' />
    </ClockWrapper>
  );
};

const ClockNumber = ({ number, text }: { number: number; text: string }) => {
  return (
    <ClockNumWrapper>
      <Div $flex $column $alignCenter $gap='6px' $p='10px 30px' $pSM='0'>
        <GradientText>
          <h1>{number}</h1>
        </GradientText>
        <p>{text}</p>
      </Div>
    </ClockNumWrapper>
  );
};

export default CountdownComponent;
