import Vimeo from '@u-wave/react-vimeo';
import { InputComponent } from 'components/Input/Input';
import { TextareaComponent } from 'components/Textarea/Textarea';
import {
  Button,
  DownloadButtonWrapper,
  GradientText,
  Main,
} from 'pages/Home/Home.styled';
import { useState } from 'react';
import styled from 'styled-components';
import {
  CaptionText,
  Div,
  DownloadIcon,
  H1,
  H2,
  H3,
  H4,
  H5,
  Img,
  P1,
  P2,
  PlayIcon,
  VideoComingSoon,
  colors,
} from 'styles';
import getInTouch from '../../assets/images/getInTouch.png';
import noVideoImage from '../../assets/images/noVideoImage.png';
import sec2img1 from '../../assets/images/sec2img1.png';
import sec2img2 from '../../assets/images/sec2img2.png';
import video1Image from '../../assets/images/video1Image.png';
import video2Image from '../../assets/images/video2Image.png';
import video3Image from '../../assets/images/video3Image.png';
import video4Image from '../../assets/images/video4Image.png';
import { Footer } from '../../components/Footer/Footer';
import { Nav } from '../../components/Nav/Nav';
import {
  Section1Wrapper,
  Section2Wrapper,
  Section3Wrapper,
  Section5Wrapper,
} from './Resources.styled';
import { openPdfInNewWindow } from 'helpers/helpers';
import { ModalComponent } from 'components/Modal/Modal';
import { useNavigate } from 'react-router';
import { ErrorToast, SuccessToast } from 'components/Toasts/Toasts';
import Spinner from 'components/Spinner/Spinner';

const videos = [
  { id: '1066256105', name: 'Teaser', img: video1Image },
  { id: '1066256046', name: 'Introduction', img: video2Image },
  { id: '1072646944', name: 'Super Node', img: video3Image },
  { id: '1072649799', name: 'Technology', img: video4Image },
  { id: '', name: 'How to Get Started', img: noVideoImage },
];

export const Resources = () => {
  const navigate = useNavigate();
  const [video, setVideo] = useState(videos[0].id);

  // const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  // const handleSendEmail = (e: any) => {
  //   e.preventDefault();
  //   const body = `Name: ${name}\nEmail: ${email}\n\nMessage: ${message}`;
  //   const mailtoLink = `mailto:inquiry@akasha.info?subject=${encodeURIComponent(
  //     'Inquiry'
  //   )}&body=${encodeURIComponent(body)}`;

  //   window.location.href = mailtoLink;
  // };

  const [loading, setLoading] = useState(false);

  const handleSendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) return ErrorToast('Please enter an email.');
    if (!subject) return ErrorToast('Please enter a subject.');
    if (!message) return ErrorToast('Please enter a massage.');

    setLoading(true);

    try {
      const response = await fetch(
        'https://faas-lon1-917a94a7.doserverless.co/api/v1/web/fn-a908fd3e-12ab-4d65-9ced-e97aeaeade9a/default/akasha-transaction-email',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, subject, message }),
        }
      );

      setLoading(false);

      if (response.ok) {
        // SuccessToast('Inquiry sent successfully!');
        setIsOpen(true);
        setEmail('');
        setSubject('');
        setMessage('');
      } else {
        const data = await response.json();
        ErrorToast(`Error: ${data.message || 'Subscription failed'}`);
      }
    } catch (error: any) {
      ErrorToast(`Network Error: ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinner fullscreen={true} transparent />}

      <Nav resources />
      <Section1Wrapper>
        <Main>
          <GradientText>
            <H1 center>Akasha Resource Center</H1>
          </GradientText>
          <H3 color={colors.white} center mb='100'>
            Everything you need to know, all in one place.
          </H3>
          <Div $flex $center $mb='55px' $desktop>
            <Vimeo video={video} width={'1000'} />
          </Div>
          <Div $flex $center $mb='55px' $mobile>
            <Vimeo video={video} width={'350'} />
          </Div>
          <VideoBoxesContainer>
            {videos.map(({ id, name, img }) => (
              <Div
                $pointer={id ? true : false}
                onClick={() => {
                  if (!id) return;
                  setVideo(id);
                }}
              >
                <VideoBox isSelected={video === id} img={img}>
                  {id && <PlayIcon />}
                  {!id && (
                    <ComingSoonStyled>
                      <VideoComingSoon />
                    </ComingSoonStyled>
                  )}
                  <span></span>
                </VideoBox>
                <P1 mt='20' color={colors.white} center>
                  {name}
                </P1>
              </Div>
            ))}
          </VideoBoxesContainer>
        </Main>
      </Section1Wrapper>
      <Section2Wrapper>
        <Main>
          <Div $flex $column $alignCenter>
            <GradientText>
              <H1 center mb='30'>
                See our Work in Action
              </H1>
            </GradientText>
            <P2 mb='100' center color={colors.white} maxWidth='800px'>
              Explore Akasha’s latest resources, insights, and innovations. From
              cutting-edge developments to practical applications, see how we
              bring ideas to life.
            </P2>
          </Div>
          <GridWrapper>
            <Section2Box img={sec2img1}>
              <div></div>
              <Div $p='30px' $flex $column $justifyCenter>
                <H4 left mb='10' color={colors.white}>
                  Official Presentation
                </H4>
                <CaptionText left mb='20' color={colors.white}>
                  The universe is intricately connected, with each part serving
                  a purpose. Technology, at its highest form, reflects this
                  complexity, mirroring creation itself.{' '}
                </CaptionText>
                <DownloadButtonWrapper
                  onClick={() =>
                    openPdfInNewWindow('/Akasha Presentation PDF.pdf')
                  }
                >
                  <GradientText>
                    <P2>Download PDF</P2>
                  </GradientText>
                  <DownloadIcon />
                </DownloadButtonWrapper>
              </Div>
            </Section2Box>

            {/* <Section2Box img={sec2img3}> */}
            {/* <div></div>
              <Div $p='30px' $flex $column $justifyCenter>
                <H4 left mb='10' color={colors.white}>
                  Brand Guidelines
                </H4>
                <CaptionText left mb='20' color={colors.white}>
                  The Akasha Brand Guidelines provide essential rules and
                  resources to ensure a consistent and cohesive brand identity.
                  From logo usage to color palettes and typography, this guide
                  helps maintain a strong and professional visual presence.
                </CaptionText>
                <DownloadButtonWrapper>
                  <GradientText>
                    <P2>Download PDF</P2>
                  </GradientText>
                  <DownloadIcon />
                </DownloadButtonWrapper>
              </Div> */}
            {/* </Section2Box> */}

            <Section2Box img={sec2img2}>
              <div></div>
              <Div $p='30px' $flex $column $justifyCenter>
                <H4 left mb='10' color={colors.white}>
                  Litepaper
                </H4>
                <CaptionText left mb='20' color={colors.white}>
                  A litepaper is a shorter, more accessible version of a
                  whitepaper that provides a high-level overview of a project,
                  concept, or product. It summarizes key information in a
                  concise and easy-to-understand format, making it ideal for
                  quick insights.
                </CaptionText>
                <DownloadButtonWrapper
                  onClick={() => openPdfInNewWindow('/Akasha Litepaper.pdf')}
                >
                  <GradientText>
                    <P2>Download PDF</P2>
                  </GradientText>
                  <DownloadIcon />
                </DownloadButtonWrapper>
              </Div>
            </Section2Box>
          </GridWrapper>
        </Main>
      </Section2Wrapper>

      <Section3Wrapper>
        <Div $mobile>
          <Img src={getInTouch} $width={'100%'} />
        </Div>
        <Main>
          <Div $flex $column $center>
            <GradientText>
              <H1 center mt='500' mtSM='0' maxWidth='900px' mb='30'>
                Get in touch with our team
              </H1>
            </GradientText>
            <H3 mb='100' center color={colors.white} maxWidth='900px'>
              Need help with something? Get in touch with our friendly team and
              we’ll get in touch.
            </H3>
          </Div>
          <form onSubmit={handleSendEmail}>
            <Div $flex $center $column>
              <Div
                $flex
                $gap='40px'
                $columnSM
                $gapSM='0'
                $width='100%'
                $maxWidth='1000px'
              >
                <Div $width='50%' $widthSM='100%'>
                  <InputComponent
                    label='Email'
                    type='email'
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                  />
                </Div>
                <Div $width='50%' $widthSM='100%'>
                  <InputComponent
                    label='Subject'
                    value={subject}
                    onChange={(e: any) => setSubject(e.target.value)}
                  />
                </Div>
              </Div>
              <Div $width='100%' $maxWidth='1000px' $mb='50px'>
                <TextareaComponent
                  label='Message'
                  value={message}
                  onChange={(e: any) => setMessage(e.target.value)}
                />
              </Div>
              <Button type='submit'>
                <GradientText>
                  <P2> Send Inquiry</P2>
                </GradientText>
              </Button>
            </Div>
          </form>
        </Main>
      </Section3Wrapper>

      <Section5Wrapper>
        <Main>
          <Div $flex $center $mb='55px' $desktop>
            <Vimeo video={'1066256046'} width={'1000'} />
          </Div>
          <Div $flex $center $mb='55px' $mobile>
            <Vimeo video={'1066256046'} width={'350'} />
          </Div>
        </Main>
      </Section5Wrapper>

      <Footer />

      <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen}>
        <Div $flex $column $center $p='50px 0 30px 0' $pSM='30px 0'>
          <GradientText>
            <H2 center mb='30'>
              Thank you for <br /> contacting us!
            </H2>
          </GradientText>
          <H5 color={colors.white} center mb='30'>
            We have received your message and will <br /> contact you shortly to
            follow up.
          </H5>
          <Button
            onClick={() => {
              setIsOpen(false);
              navigate('/');
            }}
          >
            <GradientText>
              <P2>Back to home</P2>
            </GradientText>
          </Button>
        </Div>
      </ModalComponent>
    </>
  );
};

const Section2Box = styled.div<{ img: any }>`
  display: flex;
  overflow: hidden;
  border-radius: 25px;

  > {
    :nth-child(1) {
      width: 55%;
      min-height: 300px;
      background-image: url(${({ img }) => img});
      background-position: left center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1000px) {
        width: 100%;
      }
    }
    :nth-child(2) {
      width: 45%;
      box-shadow: 0px 6.62px 21.43px 0px #ffffff52 inset;
      @media (max-width: 1000px) {
        width: 100%;
      }
      /* backdrop-filter: blur(4.877281665802002px); */
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const VideoBoxesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 30px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  /* @media (max-width: 500px) {
    grid-template-columns: 1fr;
  } */
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const VideoBox = styled.div<{ isSelected: boolean; img: any }>`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #909090;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s all ease;

  span {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.3s all ease;
    backdrop-filter: blur(10.699999809265137px);
    ${({ isSelected }) => (!isSelected ? 'opacity: 1;' : 'opacity: 0;')}
  }
  svg {
    z-index: 2;
  }

  @media (max-width: 1000px) {
    height: 150px;
  }
`;

const ComingSoonStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;
